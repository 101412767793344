import React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function newsPreview({
  slug,
  visuelFile,
  visuelDescription,
  date,
  title,
  timeReading,
  isLarge,
}) {
  const labelReading = timeReading
    ?.toLowerCase()
    .replace('min', 'minutes')
    .replace('mn', 'minutes');

  return (
    <Link
      to={`/${slug}`}
      className={`news is-clickable has-background-white ${
        isLarge ? 'is-large' : ''
      }`}
    >
      <div>
        <GatsbyImage
          as="figure"
          className="is-fullwidth-image image"
          image={getImage(visuelFile)}
          alt={visuelDescription || ''}
        />
        <div className="content p-5">
          <p className="subtitle mb-5 is-4">{date}</p>
          <p className="title is-2">{title}</p>
        </div>
      </div>
      <p
        aria-label={labelReading}
        className="time-reading p-5"
        dangerouslySetInnerHTML={{
          __html: timeReading,
        }}
      />
    </Link>
  );
}

export default newsPreview;
