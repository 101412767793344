import React from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import AnimateLink from '../animateLink';

import NewsPreview from '../news/newsPreview';

const NavigationBarNews = ({ previous, next }) => {
  return (
    <>
      <section className="is-fullwidth bkg-color2 other-news news-list">
        <h3 className="title is-3 has-text-white">
          {' '}
          <FormattedMessage id="news.related" />{' '}
        </h3>
        <div className="grid_news">
          {previous?.seo?.slug && (
            <NewsPreview
              slug={previous.seo.slug}
              visuelFile={previous.visuelMajeur.visuel}
              visuelDescription={
                previous.visuelMajeur.visuel?.file?.description
              }
              date={previous.date}
              title={previous.titre}
              timeReading={previous.tempsDeLecture}
            />
          )}
          {next?.seo?.slug && (
            <NewsPreview
              slug={next.seo.slug}
              visuelFile={next.visuelMajeur.visuel}
              visuelDescription={next.visuelMajeur.visuel?.file?.description}
              date={next.date}
              title={next.titre}
              timeReading={next.tempsDeLecture}
            />
          )}
        </div>
        <AnimateLink url="news" cssClassLink="link is-text-indication">
          <FormattedMessage id="news.view_all" />{' '}
          <span className="icon-arrow"></span>
        </AnimateLink>
      </section>
    </>
  );
};

export default injectIntl(NavigationBarNews);
