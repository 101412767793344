import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import languageHelper from '../utils/helpers/languageHelper';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import MainPlaceholderVideo from '../components/blockContent/mainPlaceholderVideo';
import FullwidthCarousel from '../components/blockContent/fullwidthCarousel';
import TextWithTitle from '../components/textWithTitle';
import FullwidthPlaceholderVideo from '../components/blockContent/fullwidthPlaceholderVideo';
import ProjectAssetsGallery from '../components/blockContent/projectAssetsGallery';
import ProjectAssetsCarousel from '../components/blockContent/projectAssetsCarousel';
import Verbatim from '../components/blockContent/verbatim/verbatim';
import BackToTop from '../components/navigation/backToTop';
import NavigationBarNews from '../components/navigation/navigationBarNews';

const PageActu = ({ data }) => {
  const page = data.actu;
  const { locales, navigationPrevious, navigationNext } = data;
  const navigation = {
    previous: { ...navigationPrevious },
    next: { ...navigationNext },
  };

  const languageTo = languageHelper.getLanguageTo(locales.nodes);

  return (
    <>
      <Layout headerCssClass="bkg-color2" mainCssClass="page-actu">
        <SEO
          title={page.seo?.metaTitle}
          titleOnglet={page.seo?.title}
          description={page.seo?.metaDescription}
          imageLink={page.seo?.socialImage?.file?.url}
          pageLink={page.seo?.slug}
        />

        {/* HeroTitle */}
        <HeroTitle
          subtitle={page?.date}
          title={page?.titre}
          catchphrase={page?.tempsDeLecture}
          isNews={true}
        />

        {/* MainPlaceholderVideo */}
        <MainPlaceholderVideo media={page?.visuelMajeur} />

        {/* START CUSTOM BLOCKS */}
        {page?.blocsLibres &&
          page?.blocsLibres?.map((block, i) => {
            switch (block.__typename) {
              case 'ContentfulCarrouselFullwidth':
                return <FullwidthCarousel key={i} visuels={block.visuels} />;

              case 'ContentfulFullWidthMedia':
                return (
                  <FullwidthPlaceholderVideo
                    key={i}
                    title={block.title}
                    visuel={block.visuel}
                    videoLink={block.videoLink}
                  />
                );

              case 'ContentfulTitreGalerieImageLienDownload':
                return (
                  <ProjectAssetsGallery
                    key={i}
                    title={block.title}
                    visuels={block.visuels}
                    downloadLink={block.downloadLink}
                  />
                );

              case 'ContentfulBlocCitation':
                return <Verbatim verbatim={block} key={i} />;

              case 'ContentfulTexteAvecUnTitre':
                return (
                  <TextWithTitle
                    key={i}
                    title={block.title}
                    text={
                      block.childContentfulTexteAvecUnTitreTextTextNode
                        .childMarkdownRemark.html
                    }
                    fullWidth
                  />
                );

              default:
                break;
            }
          })}
        {/* END CUSTOM BLOCKS */}

        {/* BackToTop */}
        <BackToTop />

        {/* NavigationBarNews */}
        <NavigationBarNews
          previous={navigation.previous}
          next={navigation.next}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query contentfulPageActualiteTemplate(
    $contentfulId: String!
    $language: String!
    $previous: String
    $next: String
  ) {
    actu: contentfulPageActualite(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $language }
    ) {
      id
      node_locale
      titre
      tempsDeLecture
      date
      seo {
        metaTitle
        metaDescription
        socialImage {
          file {
            url
          }
        }
        title
        slug
      }
      blocsLibres {
        __typename
        ... on Node {
          ... on ContentfulCarrouselFullwidth {
            id
            visuels {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
          ... on ContentfulFullWidthMedia {
            id
            visuel {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
            title
            videoLink
          }
          ... on ContentfulTitreGalerieImageLienDownload {
            id
            title
            isSlider
            downloadLink
            visuels {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
          ... on ContentfulTexteAvecUnTitre {
            id
            childContentfulTexteAvecUnTitreTextTextNode {
              childMarkdownRemark {
                html
              }
            }
            title
          }
          ... on ContentfulBlocCitation {
            id
            verbatim {
              childMarkdownRemark {
                html
              }
            }
            author
            status
          }
        }
      }

      visuelMajeur {
        visuel {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        backgroundVideo {
          file {
            url
          }
        }
        videoLink
      }
    }
    locales: allContentfulPageActualite(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        seo {
          slug
        }
      }
    }
    navigationPrevious: contentfulPageActualite(
      contentful_id: { eq: $previous }
      node_locale: { eq: $language }
    ) {
      seo {
        slug
      }
      titre
      date
      tempsDeLecture
      visuelMajeur {
        visuel {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
      }
    }
    navigationNext: contentfulPageActualite(
      contentful_id: { eq: $next }
      node_locale: { eq: $language }
    ) {
      seo {
        slug
      }
      titre
      date
      tempsDeLecture
      visuelMajeur {
        visuel {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
      }
    }
  }
`;

export default injectIntl(PageActu);
